<template>
  <Layout>
    <div class="content" slot="body">
      <header>
        <div class="header flex">
          <div class="name" v-html="title"></div>
          <div class="right flex">
            <ul class="flex">
              <li
                :class="name === 'intro' ? 'active' : ''"
                @click="handlePage('intro')"
              >
                <router-link :to="'/pdtdetail/' + pageNum + '?type=' + pageNum"
                  >产品概述</router-link
                >
              </li>
              <li
                :class="name === 'specification' ? 'active' : ''"
                @click="handlePage('specification')"
              >
                <router-link :to="'/pdtdetail/specification?type=' + pageNum"
                  >规格参数</router-link
                >
              </li>
              <li
                :class="name === 'support' ? 'active' : ''"
                @click="handlePage('support')"
              >
                <router-link :to="'/pdtdetail/support?type=' + pageNum"
                  >支持与帮助</router-link
                >
              </li>
            </ul>

            <!-- <div class="buy">购买</div> -->
          </div>
        </div>
      </header>

      <main>
        <router-view></router-view>
      </main>
    </div>
  </Layout>
</template>

<script>
  // import { Component, Prop, Vue, Ref } from "vue-property-decorator";
  import Layout from "@/components/Layout-sub.vue"; // @ is an alias to /src

  export default {
    components: {
      Layout,
    },
    data() {
      return {
        title: "",
        path: "",
        tem: require("@/assets/product/img/num.png"),
        time: require("@/assets/product/img/time.png"),
        pageNum: this.$route.query?.type || "prevone",
        name: this.$route?.name || "intro",
      };
    },
    created() {
      const type = this.$route.query?.type;
      this.path = this.$route.path;

      if (type === "prevone") {
        this.title = "215kW 风冷储能系统";
      } else if (type === "prevtwo") {
        this.title = "230kW - 110kW -2h工商业液冷储能系统";
      } else if (type === "prevthree") {
        this.title = "400kW - 200kW -2h工商业液冷储能系统";
      } else if (type === "prevfour") {
        this.title = "800kW - 200kW -4h工商业液冷储能系统";
      } else if (type === "prevstorage") {
        this.title = `<span style="color: #EB4F27;">PowerMars</span>储能式充电系统`;
      }
    },
    methods: {
      handlePage(t) {
        this.name = t;
      },
      redirectTo() {
        this.$router.go(-1);
      },
    },
  };
</script>

<style lang="less">
  .content {
    background: #000000;
    padding-top: 9.8rem;
    min-height: 100vh;

    header {
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      z-index: 9;
      background: #000000;
      padding: 0 8.8rem;

      .header {
        height: 9rem;
        align-items: center;
        justify-content: space-between;
        border-bottom: 0.1rem solid #595757;

        .name {
          color: #ffffff;
          font-size: 3.6rem;
        }

        .right {
          align-items: center;

          ul {
            color: #9fa0a0;
            font-size: 1.8rem;
            font-family: HarmonyLight;

            a {
              color: inherit;
              font-size: 2.4rem;
            }

            li:nth-child(2) {
              margin: 0 8.4rem;
            }

            li.active a {
              position: relative;
              color: #ffffff;

              &::after {
                content: "";
                position: absolute;
                bottom: -3.3rem;
                left: 0;
                height: 0.2rem;
                width: 100%;
                background: #ffffff;
              }
            }
          }

          .buy {
            width: 8.4rem;
            height: 3.4rem;
            text-align: center;
            line-height: 3.4rem;
            background: #2ea7e0;
            border-radius: 0.4rem;
            color: #ffffff;
            font-size: 1.8rem;
            font-weight: bold;
            margin-left: 6.7rem;
          }
        }
      }
    }
  }
  .footer-content {
    background: #000000;
    overflow: auto;
  }

  .sum {
    .bg {
      min-height: 70rem;
      background-color: #010102;

      .slogan {
        color: #ffffff;
        font-size: 4rem;
        font-weight: bold;
        text-align: left;
        margin-left: 19.6rem;
        margin-top: 23.3rem;

        .big {
          margin-bottom: 1.7rem;
        }

        .small {
          align-items: center;
          color: #dcdddd;
          font-size: 1.8rem;

          .tag {
            background: #c30d23;
            padding: 0.1rem 0.8rem;
            border-radius: 0.4rem;
            color: #ffffff;
            margin-left: 1.4rem;
          }
        }
      }
    }

    .list {
      width: 120rem;
      margin: 20rem auto 0;

      .item {
        width: 38.8rem;
        height: 29.1rem;
        background-color: #333333;
        border-radius: 2rem;
        margin-bottom: 1.8rem;
      }

      .line3 {
        .item {
          width: 38.8rem;

          &:nth-child(2) {
            margin: 0 1.8rem;
          }
        }
      }

      .line2 {
        .item {
          width: 59.2rem;

          &:last-of-type {
            margin-left: 1.7rem;
          }
        }
      }
    }

    .wall {
      width: 120rem;
      margin: 0 auto;

      .cards {
        margin: 15rem auto 0;

        .title {
          flex-direction: column;
          align-items: center;
          color: #ffffff;
          font-size: 4rem;
          font-weight: bold;
          margin-bottom: 2.4rem;

          span {
            font-size: 1.8rem;
            // margin-top: 3.8rem;
          }
        }

        .card {
          min-height: 60rem;
          background: #333333;
          border-radius: 2rem;
        }
      }
    }

    .banner-box {
      color: #ffffff;
      font-weight: 500;

      .title {
        text-align: left;
        font-size: 4rem;
        width: 134.6rem;
        margin: 18rem auto 1.4rem;
      }
      .subtitle {
        text-align: left;
        font-size: 1.8rem;
        width: 134.6rem;
        margin: 0 auto 2.4rem;
      }
      .banner {
        height: 79.5rem;
        background: #333333;
      }
    }

    > .btm {
      width: 120rem;
      margin: 18rem auto 0;

      .h1 {
        font-size: 4rem;
        color: #ffffff;
        font-weight: bold;
        margin: 10rem auto;
      }

      .h2 {
        color: #ffffff;
        font-size: 4rem;
        font-weight: bold;
        margin: 18rem 0 10.8rem;
      }

      .h4 {
        font-size: 1.8rem;
        font-weight: bold;
        color: #ffffff;
        margin-top: 14.6rem;
      }

      .area {
        align-items: center;
        .img {
          flex-shrink: 0;
          width: 60rem;
          height: 40rem;
          background-color: #333333;
          border-radius: 2rem;
        }
        .img-left {
          margin-left: 12.9rem;
        }
        .img-right {
          margin-right: 8.2rem;
        }

        .text {
          height: 40rem;
          align-items: flex-start;
          justify-content: center;
          flex: 1;
          flex-direction: column;

          &.parts {
            justify-content: space-between;
          }

          .txt {
            text-align: left;
            font-size: 1.8rem;
            line-height: 3.2rem;
            color: #ffffff;
            font-weight: bold;
          }

          .title {
            color: #ffffff;
            font-size: 4rem;
            font-weight: bold;
            margin-bottom: 3rem;
          }
        }

        .tem {
          flex-direction: column;

          .font {
            height: 10.8rem;
            // margin-top: 7.8rem;
          }

          .des {
            color: #9fa0a0;
            font-size: 1.8rem;
            font-weight: bold;
            margin-top: 1.2rem;
          }
        }
      }

      .icon-txt {
        justify-content: space-between;
        padding: 0 8.4rem;

        .item {
          max-width: 30.6rem;
          flex-direction: column;
          align-items: center;

          .icon {
            width: 12rem;
            height: 12rem;
            border-radius: 50%;
            background-color: #333333;
            margin-bottom: 4.5rem;
          }

          .word {
            color: #ffffff;
            font-size: 1.8rem;
            font-weight: bold;
            text-align: center;
          }
        }
      }

      .mid-banner {
        height: 60rem;
        background-color: #333333;
        border-radius: 2rem;
        margin-top: 19rem;
      }

      .banner-list {
        margin: 8rem 0 0;

        .line1,
        .line2 {
          justify-content: space-between;
          .item {
            border-radius: 2rem;
            background-color: #333333;

            .btm {
              flex-direction: column;
              margin-top: 24rem;
              padding: 0 1.6rem;

              .title {
                color: #ffffff;
                font-size: 2.8rem;
                line-height: 2.8rem;
                font-weight: bold;
                margin-bottom: 1.4rem;
              }

              .text {
                color: #9fa0a0;
                font-size: 1.8rem;
                font-weight: 300;
                text-align: left;
              }
            }
          }
        }

        .line1 {
          margin-bottom: 2.4rem;
          .item {
            width: 28.5rem;
            height: 44.6rem;
          }
        }

        .line2 {
          .item {
            width: 38.7rem;
            height: 32.6rem;
          }
        }
      }

      .slide-box {
        position: relative;
        align-items: center;
        padding-bottom: 3.8rem;

        .imgs {
          flex-shrink: 0;
          width: 80rem;
          height: 50rem;
          border-radius: 2rem;
          background-color: #333333;
          margin-right: 15rem;
        }

        .operate {
          flex: 1;
          flex-direction: column;
          border-left: 0.2rem solid #9fa0a0;
          padding: 2.1rem 0 0 5.6rem;

          .btn {
            color: #9fa0a0;
            font-size: 2.8rem;
            font-weight: bold;
            cursor: pointer;

            &:not(:last-of-type) {
              margin-bottom: 6.5rem;
            }

            &.active {
              position: relative;
              color: #ffffff;

              &::after {
                position: absolute;
                content: "";
                height: 7.8rem;
                width: 0.2rem;
                background-color: #ffffff;
                left: -5.8rem;
                top: 50%;
                transform: translateY(-50%);
              }
            }
          }
        }

        .tips {
          position: absolute;
          left: 0;
          bottom: 0;
          font-size: 1.8rem;
          font-weight: bold;
          color: #ffffff;
        }
      }

      .mode {
        overflow: hidden;
        .area {
          margin-bottom: 18.2rem;
        }
      }
    }
  }
</style>
