





















































































































































import { Component, Vue } from "vue-property-decorator";
export default class Footer extends Vue {
  wchat = require("@/assets/common/img/wechat.png");
  weibo = require("@/assets/common/img/weibo.png");
  tk = require("@/assets/common/img/tk.png");
  red = require("@/assets/common/img/red.png");
  logoh = require("@/assets/common/img/logo-h.png");
  logot = require("@/assets/common/img/logo-t.png");
  global = require("@/assets/common/img/global.png");

  pop() {
    this.$message({
      message: "敬请期待",
    });
  }
}
