import $this from '../main'

window.onresize = () => {
    console.log('resize');
    var docEl = document.documentElement;
    var width = docEl.getBoundingClientRect().width;
    var rem;
    if (width > 768) {
        rem = width / 192;
        $this.$store.commit("judgeIsPc", true);
    } 
    // else {
    //     rem = width / 375;
    //     $this.$store.commit("judgeIsPc", false);
    // }
    docEl.style.fontSize = rem + "px";
}