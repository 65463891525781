import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isPc: true,
  },
  mutations: {
    judgeIsPc(state, params) {
      state.isPc = params;
      console.log("judgeIsPc", params);
    },
  },
  actions: {},
  modules: {},
});
