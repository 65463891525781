































































































import { Component, Prop, Vue, Ref } from "vue-property-decorator";
import Layout from "@/components/Layout-sub.vue"; // @ is an alias to /src

@Component({
  components: {
    Layout,
  },
})
export default class Storage extends Vue {
  @Ref("svgScale") readonly svgBox!: HTMLElement;

  bg_one = require("@/assets/home/img/new1.png");
  bg_two = require("@/assets/home/img/new2.png");
  pdt_one = require("@/assets/home/img/new7.png");
  pdt_two = require("@/assets/home/img/new4.png");
  pdt_three = require("@/assets/home/img/new5.png");
  pdt_four = require("@/assets/home/img/new6.png");

  pdts = [
    {
      id: 1,
      title: "家庭绿电",
      subtitle: "开启零碳品质全新生活",
      img: require("@/assets/home/img/new8.png"),
      imgStyle: {},
      url: "",
    },
    {
      id: 2,
      title: "行业绿电",
      subtitle: "一站式储充解决方案",
      img: require("@/assets/home/img/new9.png"),
      imgStyle: {},
      url: "",
    },
    {
      id: 3,
      title: "充电网络",
      subtitle: "打造高质量的智能充电网络",
      img: require("@/assets/home/img/new10.png"),
      imgStyle: {},
      url: "",
    },
  ];

  navigate(path: string) {
    // return false;
    this.$router.push({
      path,
    });
  }
}
