





























































































































































































































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class Header extends Vue {
  logo: string = require("@/assets/logo-white.png");
  hoverLogo: string = require("@/assets/common/img/logo-h.png");
  menu: string = require("@/assets/common/img/menu.png");
  ishover = false;
  ishover_two = false;
  ishoverModal = false; //控制背景毛玻璃
  isfix = false;
  prevScroll = 0;
  drawer = false;
  isSlide = false;
  isfixed = true;

  created() {
    console.log("created header");
    if (this.$route.path.indexOf("pdtdetail") == -1) {
      this.isfixed = true;
    } else {
      this.isfixed = false;
    }
  }

  pdts: { [key: number]: any } = {
    1: [
      {
        id: 1,
        isNew: true,
        img: require("@/assets/mid/m1.png"),
        name: "PowerStorage Air",
        url: "/pdtdetail/prevtwo?type=prevtwo",
        imgH: "17rem",
      },
    ],
    2: [
      {
        id: 1,
        isNew: true,
        img: require("@/assets/mid/m2.png"),
        name: "PowerStorage Liquid",
        url: "/pdtdetail/prevone?type=prevone",
        imgH: "",
      },
    ],
  };

  //     interface Foo {
  //   [key: string]: any
  // }

  pdts_two: { [key: number]: any } = {
    1: [
      {
        id: 1,
        isNew: false,
        img: require("@/assets/mid/m6.png"),
        name: "PowerBuru",
        url: "/pdtdetail/prevtwo?type=prevtwo",
        imgH: "15.4rem",
        imgMr: "6rem",
      },
      {
        id: 2,
        isNew: false,
        img: require("@/assets/mid/m7.png"),
        name: "PowerToge",
        url: "/pdtdetail/prevtwo?type=prevtwo",
        imgH: "",
        imgMr: "",
      },
    ],
    2: [
      {
        id: 1,
        isNew: false,
        img: require("@/assets/mid/m8.png"),
        name: "PowerNaron",
        url: "/pdtdetail/prevtwo?type=prevtwo",
        imgH: "20rem",
        imgMr: "19.8rem",
      },
      {
        id: 2,
        isNew: false,
        img: require("@/assets/mid/m9.png"),
        name: "PowerSurt",
        url: "/pdtdetail/prevtwo?type=prevtwo",
        imgH: "",
        imgMr: "0",
      },
    ],
    3: [
      {
        id: 1,
        isNew: false,
        img: require("@/assets/mid/m10.png"),
        name: "PowerHairu",
        url: "/pdtdetail/prevtwo?type=prevtwo",
        imgH: "",
        imgMr: "6.6rem",
      },
      {
        id: 2,
        isNew: false,
        img: require("@/assets/mid/m11.png"),
        name: "PowerSissi",
        url: "/pdtdetail/prevtwo?type=prevtwo",
        imgH: "18.9rem",
        imgMr: "6.6rem",
      },
      {
        id: 3,
        isNew: false,
        img: require("@/assets/mid/m12.png"),
        name: "PowerSpe",
        url: "/pdtdetail/prevtwo?type=prevtwo",
        imgH: "",
        imgMr: "0",
      },
    ],
    4: [
      {
        id: 1,
        isNew: false,
        img: require("@/assets/mid/m13.png"),
        name: "PowerLete",
        url: "/pdtdetail/prevtwo?type=prevtwo",
        imgH: "17.2rem",
        imgMr: "",
      },
    ],
    5: [
      {
        id: 1,
        isNew: false,
        img: require("@/assets/mid/m14.png"),
        name: "PowerMars",
        url: "/pdtdetail/prevstorage?type=prevstorage",
        imgH: "",
        imgMr: "",
      },
    ],
  };

  type = 1;

  scrollStyle = {
    transform: "translateY(0)",
  };

  showbg = false;

  handleType(t: number) {
    this.type = t;
  }

  mounted() {
    if (this.isfixed) return;
    window.addEventListener("scroll", this.handleScroll);
  }
  handleScroll() {
    // const Header = this.$refs.header as HTMLElement;
    // const headerHeight = Header.offsetHeight;
    let scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop;

    this.scrollStyle.transform = `translateY(-${scrollTop}px)`;
    // if (scrollTop > headerHeight && scrollTop < this.prevScroll) {
    //   this.isfix = true;
    //   console.log("up");

    //   // this.logo = this.hoverLogo;
    // } else {
    //   console.log("down", scrollTop);

    //   this.isfix = false;
    //   // this.logo = require("@/assets/logo-white.png");
    // }
    // this.prevScroll = scrollTop;
  }

  Back() {
    this.$router.push({
      path: "/index",
    });
  }

  showBG(t: string) {
    this.showbg = true;
    this.ishoverModal = true;
    if (t === "one") {
      this.ishover = true;
    } else if (t === "two") {
      this.ishover_two = true;
    }
  }

  hideBG(t: string) {
    this.type = 1;
    this.showbg = false;
    this.ishoverModal = false;
    if (t === "one") {
      this.ishover = false;
    } else if (t === "two") {
      this.ishover_two = false;
    }
  }

  showSlide() {
    this.isSlide = true;
  }

  hideSlide() {
    this.isSlide = false;
  }

  waiting() {
    this.$message({
      message: "敬请期待...",
      showClose: true,
      customClass: "messageIndex",
    });
  }

  toClean() {
    this.$router.push({
      path: "/clean",
    });
  }

  toCharge() {
    this.$router.push({
      path: "/charge",
    });
  }

  navigate(path: string) {
    // return false;
    this.ishover = false;
    this.ishover_two = false;
    this.ishoverModal = false;
    this.$router.push({
      path,
    });
  }
}
