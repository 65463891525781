import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
// import HomeView from "../views/HomeView.vue";
// import Home from "../views/home/Home.vue";
import Home from "../views/mid/clean.vue";
import Detail from "../views/products/index.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/index",
    name: "Index",
    component: Home,
  },
  {
    path: "/mid/clean",
    name: "Midclean",
    component: () =>
      import(/* webpackChunkName: "Midclean" */ "../views/mid/clean.vue"),
  },
  {
    path: "/pdtdetail",
    name: "PdtDetail",
    component: Detail,
    children: [
      {
        path: "prevstorage",
        name: "intro",
        component: () => import("../views/products/components/prevstorage.vue"),
      },
      {
        path: "prevone",
        name: "prevone",
        component: () => import("../views/products/components/prevone.vue"),
      },
      {
        path: "prevtwo",
        name: "prevtwo",
        component: () => import("../views/products/components/prevtwo.vue"),
      },
      {
        path: "prevthree",
        name: "prevthree",
        component: () => import("../views/products/components/prevthree.vue"),
      },
      {
        path: "prevfour",
        name: "prevfour",
        component: () => import("../views/products/components/prevfour.vue"),
      },
      {
        path: "specification",
        name: "specification",
        component: () =>
          import("../views/products/components/specification.vue"),
      },
      {
        path: "support",
        name: "support",
        component: () => import("../views/products/components/support.vue"),
      },
    ],
  },
  {
    // 社会
    path: "/solarvillage",
    name: "SolarVillage",
    component: () =>
      import(
        /* webpackChunkName: "SolarVillage" */ "../views/home/SolarVillage.vue"
      ),
  },
  {
    // 社会责任
    path: "/home/social",
    name: "social",
    component: () =>
      import(
        /* webpackChunkName: "SolarVillage" */ "../views/home/SolarVillage.vue"
      ),
  },
  {
    path: "/home/social/enviroment",
    name: "enviroment",
    component: () =>
      import(
        /* webpackChunkName: "enviroment" */ "../views/home/enviroment.vue"
      ),
  },
  {
    path: "/home/social/society",
    name: "society",
    component: () =>
      import(/* webpackChunkName: "society" */ "../views/home/society.vue"),
  },
  {
    // 关于我们
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },

  {
    // 智慧储能
    path: "/clean",
    name: "Clean",
    component: () =>
      import(
        /* webpackChunkName: "Storage" */ "../views/CleanEnergy/Storage.vue"
      ),
  },
  {
    // 智慧储能-家庭储能
    path: "/clean/homeenergy",
    name: "HomeEnergy",
    component: () =>
      import(
        /* webpackChunkName: "HomeEnergy" */ "../views/CleanEnergy/HomeEnergy.vue"
      ),
  },
  {
    // 智慧储能-商业储能
    path: "/clean/bussinessenergy",
    name: "Bussinessenergy",
    component: () =>
      import(
        /* webpackChunkName: "Bussinessenergy" */ "../views/common/Storage.vue"
      ),
  },
  {
    // 智慧储能-家庭储能-光伏->遗弃
    path: "/clean/homeenergy/solar",
    name: "Solar",
    component: () =>
      import(/* webpackChunkName: "Solar" */ "../views/Solar.vue"),
  },
  {
    // 智慧储能-家庭储能-储能墙
    path: "/clean/homeenergy/powerwall",
    name: "PowerWall",
    component: () =>
      import(
        /* webpackChunkName: "PowerWall" */ "../views/CleanEnergy/PowerWall.vue"
      ),
  },

  {
    // 零碳生活
    path: "/zeroCarbon",
    name: "ZeroCarbon",
    component: () =>
      import(
        /* webpackChunkName: "ZeroCarbon" */ "../views/zeroCarbon/ZeroCarbon.vue"
      ),
  },
  {
    // 零碳生活-零碳家庭
    path: "/zeroCarbon/zchome",
    name: "ZChome",
    component: () =>
      import(/* webpackChunkName: "ZChome" */ "../views/zeroCarbon/ZChome.vue"),
  },
  {
    // 零碳生活-零碳家庭-光伏发电(光伏详情)
    path: "/zeroCarbon/zchome/solar",
    name: "ZCsolar",
    component: () =>
      import(/* webpackChunkName: "ZCsolar" */ "../views/Solar.vue"),
  },
  {
    // 零碳生活-零碳家庭-家庭能源
    path: "/zeroCarbon/zchome/powerwall",
    name: "ZCPowerWall",
    component: () =>
      import(
        /* webpackChunkName: "ZCPowerWall" */ "../views/CleanEnergy/PowerWall.vue"
      ),
  },
  {
    // 零碳生活-零碳家庭-充电桩
    path: "/zeroCarbon/zchome/chargepile",
    name: "ZCChargePile",
    component: () =>
      import(
        /* webpackChunkName: "ZCPowerWall" */ "../views/zeroCarbon/ChargePile.vue"
      ),
  },
  {
    // 零碳生活-零碳家庭-智能家居
    path: "/zeroCarbon/zchome/smarthome",
    name: "ZCSmartHome",
    component: () =>
      import(
        /* webpackChunkName: "ZCPowerWall" */ "../views/zeroCarbon/SmartHome.vue"
      ),
  },
  {
    // 零碳生活-零碳楼宇
    path: "/zeroCarbon/zcbuilding",
    name: "ZCBuilding",
    component: () =>
      import(
        /* webpackChunkName: "ZCBuilding" */ "../views/zeroCarbon/ZCBuilding.vue"
      ),
  },
  {
    // 零碳生活-零碳楼宇-光伏详情
    path: "/zeroCarbon/zcbuilding/solar",
    name: "ZCsolar",
    component: () =>
      import(
        /* webpackChunkName: "ZCsolar" */ "../views/zeroCarbon/BuildSolar.vue"
      ),
  },
  {
    // 零碳生活-零碳楼宇-商业储能
    path: "/zeroCarbon/zcbuilding/storage",
    name: "BuildStorage",
    component: () =>
      import(
        /* webpackChunkName: "ChargePile" */ "../views/zeroCarbon/BuildStorage.vue"
      ),
  },
  {
    // 零碳生活-零碳楼宇-目的地充电
    path: "/zeroCarbon/zcbuilding/charge",
    name: "ZCCharge",
    component: () =>
      import(/* webpackChunkName: "Charge" */ "../views/zeroCarbon/Charge.vue"),
  },
  {
    // 零碳生活-零碳城市
    path: "/zeroCarbon/zccity",
    name: "ZCCity",
    component: () =>
      import(/* webpackChunkName: "ZCCity" */ "../views/zeroCarbon/ZCCity.vue"),
  },
  {
    // 零碳生活-零碳城市-光伏详情
    path: "/zeroCarbon/zccity/solar",
    name: "ZCCitySolar",
    component: () =>
      import(
        /* webpackChunkName: "ZCCitySolar" */ "../views/zeroCarbon/ZCCitySolar.vue"
      ),
  },
  {
    // 零碳生活-零碳城市-公共事业机构
    path: "/zeroCarbon/zccity/public",
    name: "ZCCityPublic",
    component: () =>
      import(
        /* webpackChunkName: "ZCCityPublic" */ "../views/zeroCarbon/ZCCityPublic.vue"
      ),
  },
  {
    // 零碳生活-零碳城市-城市充电站
    path: "/zeroCarbon/zccity/chargestation",
    name: "ZCCityChargestation",
    component: () =>
      import(
        /* webpackChunkName: "ZCCityChargestation" */ "../views/zeroCarbon/ZCCityChargestation.vue"
      ),
  },
  {
    // 零碳生活-零碳园区
    path: "/zeroCarbon/zcpark",
    name: "ZCPark",
    component: () =>
      import(/* webpackChunkName: "ZCPark" */ "../views/zeroCarbon/ZCPark.vue"),
  },
  {
    // 零碳生活-零碳园区-光伏详情
    path: "/zeroCarbon/zcpark/solar",
    name: "ZCParkSolar",
    component: () =>
      import(
        /* webpackChunkName: "ZCParkSolar" */ "../views/zeroCarbon/ZCParkSolar.vue"
      ),
  },
  {
    // 零碳生活-零碳园区-公共事业
    path: "/zeroCarbon/zcpark/public",
    name: "ZCParkPublic",
    component: () =>
      import(
        /* webpackChunkName: "ZCParkPublic" */ "../views/zeroCarbon/ZCParkPublic.vue"
      ),
  },
  {
    // 零碳生活-零碳园区-充电站
    path: "/zeroCarbon/zcpark/chargestation",
    name: "ZCParkChargestation",
    component: () =>
      import(
        /* webpackChunkName: "ZCParkChargestation" */ "../views/zeroCarbon/ZCParkChargestation.vue"
      ),
  },

  {
    // 充电生态
    path: "/charge",
    name: "Charge",
    component: () =>
      import(/* webpackChunkName: "Charge" */ "../views/Charge/index.vue"),
  },
  {
    // 充电生态-家庭充电
    path: "/charge/family",
    name: "CFamily",
    component: () =>
      import(/* webpackChunkName: "CFamily" */ "../views/Charge/Family.vue"),
  },
  {
    // 充电生态-目的地充电
    path: "/charge/destination",
    name: "CDestination",
    component: () =>
      import(
        /* webpackChunkName: "CDestination" */ "../views/Charge/Destination.vue"
      ),
  },
  {
    // 充电生态-旅途充电
    path: "/charge/trip",
    name: "CTrip",
    component: () =>
      import(/* webpackChunkName: "CTrip" */ "../views/Charge/Trip.vue"),
  },
  {
    // 充电生态-移动充电
    path: "/charge/mobile",
    name: "CMobile",
    component: () =>
      import(/* webpackChunkName: "CMobile" */ "../views/Charge/Mobile.vue"),
  },
  {
    // 充电生活
    path: "/power",
    name: "Power",
    component: () =>
      import(/* webpackChunkName: "power" */ "../views/Power.vue"),
  },

  {
    // 绿色能源投资
    path: "/investment",
    name: "Investment",
    component: () =>
      import(/* webpackChunkName: "Investment" */ "../views/Investment.vue"),
  },

  {
    // 关于我们
    path: "/aboutnew",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "About" */ "../views/Aboutnew.vue"),
  },

  {
    // 零碳伙伴
    path: "/zbpartner",
    name: "ZBpartner",
    component: () =>
      import(
        /* webpackChunkName: "ZBpartner" */ "../views/ZBpartner/ZBpartner.vue"
      ),
  },

  {
    // 产品中心
    path: "/productcenter",
    name: "ProductCenter",
    component: () =>
      import(
        /* webpackChunkName: "ProductCenter" */ "../views/ProductCenter.vue"
      ),
  },

  {
    // 订阅更多
    path: "/subscribe",
    name: "Subscribe",
    component: () =>
      import(/* webpackChunkName: "Subscribe" */ "../views/Subscribe.vue"),
  },
  {
    // 新闻文章
    path: "/article",
    name: "Article",
    component: () =>
      import(/* webpackChunkName: "Article" */ "../views/Article.vue"),
  },

  {
    // 加入我们
    path: "/join",
    name: "Join",
    component: () => import(/* webpackChunkName: "Join" */ "../views/Join.vue"),
  },
  {
    // 人才招聘
    path: "/precruit",
    name: "Precruit",
    component: () =>
      import(/* webpackChunkName: "Precruit" */ "../views/Precruit.vue"),
  },
  {
    // 办公地址
    path: "/workaddress",
    name: "WorkAddress",
    component: () =>
      import(/* webpackChunkName: "WorkAddress" */ "../views/WorkAddress.vue"),
  },
  {
    // 人才发展
    path: "/talentdev",
    name: "TalentDev",
    component: () =>
      import(/* webpackChunkName: "TalentDev" */ "../views/TalentDev.vue"),
  },
  {
    // 生活在誉照
    path: "/life",
    name: "Life",
    component: () => import(/* webpackChunkName: "Life" */ "../views/Life.vue"),
  },

  {
    // 招聘
    path: "/recruit",
    name: "Recruit",
    component: () =>
      import(/* webpackChunkName: "Recruit" */ "../views/Recruit.vue"),
  },
  {
    // 岗位详情
    path: "/jobs",
    name: "Jobs",
    component: () => import(/* webpackChunkName: "Jobs" */ "../views/Jobs.vue"),
  },
  {
    // 申请职位
    path: "/applyjobs",
    name: "ApplyJobs",
    component: () =>
      import(/* webpackChunkName: "ApplyJobs" */ "../views/ApplyJobs.vue"),
  },
  {
    // 新闻列表
    path: "/news",
    name: "News",
    component: () => import(/* webpackChunkName: "News" */ "../views/News.vue"),
  },
  {
    // 新闻列表
    path: "/test",
    name: "Test",
    component: () => import(/* webpackChunkName: "Test" */ "../views/test.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  window.pageYOffset = 0;
  next();
});

export default router;
