export default {
  bind(el: any, binding: any) {
    let oldScrolltop = 0;
    let direction = "down";
    window.addEventListener("scroll", (e) => {
      //   console.log(4, e);
      const scrolltop =
        window.pageXOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      const scrollTep = scrolltop - oldScrolltop;
      oldScrolltop = scrolltop;
      if (scrollTep > 0) {
        // console.log("下");
        direction = "down";
      } else {
        // console.log("上");
        direction = "up";
      }
    });
    const intersectionObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.target instanceof HTMLElement) {
          if (entry.isIntersecting) {
            entry.target.classList.add(entry.target.dataset.active || "active");
          } else {
            // 当网页正常向下滚动隐藏元素之后，不会再触发特效
            if (direction === "up") {
              entry.target.classList.remove(
                entry.target.dataset.active || "active"
              );
            }
          }
        }
      });
    });

    el.dataset.active = binding.value;

    intersectionObserver.observe(el);
  },
  unbind() {
    console.log("unbind");
  },
};
