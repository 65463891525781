<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/"></router-link>
    </nav> -->
    <router-view />
  </div>
</template>
<script>
  export default {
    created() {
      this.refreshRem();
      window.addEventListener("resize", this.refreshRem());
    },
    methods: {
      refreshRem() {
        var docEl = document.documentElement;
        var width = docEl.getBoundingClientRect().width;
        var rem;
        if (width > 768) {
          rem = width / 192;
          this.$store.commit("judgeIsPc", true);
        } else {
          rem = width / 375;
          this.$store.commit("judgeIsPc", false);
        }
        docEl.style.fontSize = rem + "px";
        // flexible.rem = win.rem = rem;
      },
    },
  };
</script>
<style lang="less">
  .messageIndex {
    z-index: 999999999 !important;
  }
</style>
